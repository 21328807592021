import React from 'react'
import { GrInstagram, GrFacebook, GrLinkedin, GrTwitter } from 'react-icons/gr'
import '../Styles/Footer.css'

export default function footer() {
  return (
    <div className="socialMediaIconsDiv">
      <div>
        <p>Follow us on social media!</p>
      </div>
      <div className="icons">
        <a href="https://www.facebook.com/exove" target="_blank" rel="noreferrer">
          <GrFacebook />
        </a>
        <a href="https://www.instagram.com/exove/" target="_blank" rel="noreferrer">
          <GrInstagram />
        </a>
        <a href="https://twitter.com/exove" target="_blank" rel="noreferrer">
          <GrTwitter />
        </a>
        <a href="https://www.linkedin.com/company/exove/" target="_blank" rel="noreferrer">
          <GrLinkedin />
        </a>
      </div>
    </div>
  )
}
