import React, { useState, useEffect } from 'react'
import '../Styles/Leaderboard.css'

const Leaderboard = () => {
  const [leaders, setLeaders] = useState([])

  function fetchLeaderboard() {
    fetch('/api/score')
      .then((res) => res.json())
      .then((result) => {
        setLeaders(result)
      })
  }

  // on load, fetch the leaderboard scores, scroll to top and reset score
  useEffect(() => {
    fetchLeaderboard()
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="frontPageDiv">
      <div className="leaderBoardDiv">
        <h3>Leaderboard</h3>
        <p>Scroll down to see more scores</p>
        <table className="leaderBoardList">
          <tbody>
            {leaders.map((player, i) => {
              return (
                <tr key={player.id}>
                  <td>
                    {i + 1}. {player.nickname}
                    {player.subscribed ? ' ❤️' : null}
                  </td>
                  <td>{player.bestScore}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <p>❤️ are our friends</p>
      </div>
      <div className="budgetIconDiv" />
    </div>
  )
}

export default Leaderboard
