import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Styles/Frontpage.css'
import Button from './Button'
import Leaderboard from './Leaderboard'
import Footer from './Footer'

// TODO: Check that there are no same nicknames
// TODO: Playbutton unclickable if player has invalid nickname
// TODO: Scoredata will be fetched from /api/x endpoint and then sorted in this function

export default function frontpage({ playerInfo, setPlayerInfo, setScore }) {
  const navigate = useNavigate()
  // store the inputted nickname to state
  function handleChange(e) {
    setPlayerInfo({
      ...playerInfo,
      nickname: e.target.value
    })
  }
  // save the nickname that comes from the state to local storage
  const playGameHandler = () => {
    if (playerInfo.nickname.length > 15) {
      alert('Your nickname should be maximum 15 characters long')
    } else if (playerInfo.nickname.length > 0) {
      localStorage.setItem('nickname', playerInfo.nickname)
      navigate('/game')
    } else {
      alert('Please enter a nickname!')
    }
  }

  // on load, scroll to top and reset score
  useEffect(() => {
    window.scrollTo(0, 0)
    setScore((prevState) => ({ ...prevState, currentScore: 0 }))
    // check if nickname already exists in local storage and use that and disable input field so the user can't change their nickname
    if (localStorage.getItem('nickname')) {
      setPlayerInfo({ ...playerInfo, nickname: localStorage.getItem('nickname') })
      document.getElementById('nickname').disabled = true
    }
  }, [])

  return (
    <div className="frontPageDiv">
      <div className="gameInfoTextDiv">
        <h2>XOXO Clicker</h2>
        <i>
          Push the button as fast as you can for 20 seconds! The best player with a ❤️ wins 2 movie
          tickets. Leave us your email and you’re also in for a raffle for a single movie ticket.
          Good luck!
        </i>
        <div />
        <label htmlFor="nickname">
          <input
            id="nickname"
            type="text"
            value={playerInfo.nickname}
            name="nickname"
            placeholder="Please enter your nickname"
            onChange={(e) => handleChange(e)}
          />
        </label>
      </div>
      <div className="startButtonDiv">
        <Button text="PLAY" onClick={playGameHandler} />
      </div>
      <Leaderboard />
      <Footer />
    </div>
  )
}
