import React from 'react'
import logo from '../Assets/exove-logo-black.svg'
import donut from '../Assets/exove_donut.svg'
import '../Styles/Header.css'

const Header = () => {
  return (
    <div className="header">
      <a href="/">
        <img src={donut} alt="exove brand donut" />
      </a>
      <a href="https://www.exove.com/" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="exove brand logo" />
      </a>
      {/* 
          By adding an extra donut (hidden) and using flexbox,
          the Exove logo is always centered and the visible donut is pushed to the left
         */}
      <img src={donut} alt="exove brand donut" aria-hidden="true" />
    </div>
  )
}

export default Header
