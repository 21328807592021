import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SubscriptionForm from './Components/SubscriptionForm'
import Frontpage from './Components/Frontpage'
import Clicker from './Components/Clicker'
import GameOver from './Components/GameOver'
import './App.css'
import Header from './Components/Header'

const App = () => {
  const [score, setScore] = useState({
    currentScore: 0,
    lastScore: 0,
    finished: false
  })
  const [playerInfo, setPlayerInfo] = useState({
    nickname: localStorage.getItem('nickname') ? localStorage.getItem('nickname') : '',
    firstName: '',
    lastName: '',
    email: '',
    policy: false
  })

  return (
    <div className="App">
      <div className="container">
        <Header />
        <BrowserRouter>
          <Routes>
            <Route
              index
              path="/"
              element={
                <Frontpage
                  playerInfo={playerInfo}
                  setPlayerInfo={setPlayerInfo}
                  setScore={setScore}
                />
              }
            />
            <Route
              path="/game"
              element={<Clicker score={score} setScore={setScore} playerInfo={playerInfo} />}
            />
            <Route
              path="/form"
              element={<SubscriptionForm playerInfo={playerInfo} setPlayerInfo={setPlayerInfo} />}
            />
            <Route path="/gameover" element={<GameOver score={score} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}

export default App
