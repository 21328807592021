import React from 'react'
import { Link } from 'react-router-dom'
import '../Styles/Frontpage.css'
import '../Styles/SubscriptionForm.css'
import Leaderboard from './Leaderboard'
import Footer from './Footer'

export default function gameover({ score }) {
  return (
    <div>
      <div className="subscriptionForm">
        <h3 style={{ fontWeight: 'bold' }}> Your score is </h3>
        <div className="scoreSectionDiv">
          <h1>{score.lastScore}</h1>
        </div>
        <Link to="/game" className="playAgainLink">
          <button type="button" className="playAgainButton">
            PLAY AGAIN
          </button>
        </Link>
      </div>
      <Leaderboard />
      <Footer />
    </div>
  )
}
