import React from 'react'
import star from '../Assets/star.svg'
import '../Styles/Loader.css'

const Loader = ({ heading, score, fact }) => {
  return (
    <div className="loader">
      <h1>{heading}</h1>
      {score ? <h2>{score.lastScore}</h2> : null}
      <div className="fact">
        <p>{fact}</p>
      </div>
      <img src={star} alt="exove brand star" />
    </div>
  )
}

export default Loader
