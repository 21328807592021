import loadingScreenFacts from '../Data/Facts.json'

// Shuffles the array order
function shuffle(sourceArray) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sourceArray.length - 1; i++) {
    const j = i + Math.floor(Math.random() * (sourceArray.length - i))

    const temp = sourceArray[j]
    // eslint-disable-next-line no-param-reassign
    sourceArray[j] = sourceArray[i]
    // eslint-disable-next-line no-param-reassign
    sourceArray[i] = temp
  }
  return sourceArray
}

export default function getLoadingScreenFacts() {
  return shuffle(loadingScreenFacts.loadingScreenFacts)
}
