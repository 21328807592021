import React from 'react'
import '../Styles/Frontpage.css'

export default function button({ text, onClick }) {
  // TODO: Add onClick event to switch to Game View
  return (
    <button type="button" className="startGameButton" onClick={onClick}>
      {text}
    </button>
  )
}
