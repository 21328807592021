import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Styles/SubscriptionForm.css'
import heartLogo from '../Assets/icon-heart.svg'
import Footer from './Footer'

export default function subscriptionForm({ playerInfo, setPlayerInfo }) {
  const navigate = useNavigate()

  const [fieldValidation, setFieldValidation] = useState({
    firstName: false,
    lastname: false,
    email: false
  })

  function handleChange(e) {
    if (e.target.name === 'policy' || e.target.name === 'subscribe') {
      setPlayerInfo({
        ...playerInfo,
        [e.target.name]: !playerInfo[e.target.name]
      })
    } else {
      setPlayerInfo({ ...playerInfo, [e.target.name]: e.target.value })
    }
  }

  function validateInput() {
    const errors = []
    if (playerInfo.firstName.replace(/\s/g, '').length === 0) {
      setFieldValidation({
        ...fieldValidation,
        [fieldValidation.firstName]: true
      })
      errors.push('Insert Firstname')
    }

    if (playerInfo.lastName.replace(/\s/g, '').length === 0) {
      setFieldValidation({
        ...fieldValidation,
        [fieldValidation.lastname]: true
      })
      errors.push('Insert Lastname')
    }

    if (!playerInfo.email.includes('@')) {
      setFieldValidation({ ...fieldValidation, [fieldValidation.email]: true })
      errors.push('Insert valid Email')
    }
    if (playerInfo.policy === false) {
      errors.push('Accept our privacy policy or skip the form')
    }
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const errors = validateInput()
    if (errors.length <= 0 && playerInfo.policy) {
      fetch('/api/player', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...playerInfo,
          subscribed: true
        })
      })
        .then((res) => res.json())
        .then(() => {
          localStorage.setItem('hasSubscribed', true)
          navigate('/gameover')
        })
    } else {
      alert(errors.join('\n'))
    }
  }

  const handleSkip = (event) => {
    event.preventDefault()
    navigate('/gameover')
  }

  return (
    <div className="subscriptionForm">
      <div className="subscriptionInfoDiv">
        <h2 style={{ fontWeight: 'bold' }}>Sign up for our newsletter!</h2>
        <i>
          You can relax - We don&rsquo;t spam our friends. You can skip the form if you don&rsquo;t
          want to participate in the prize pool.
        </i>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="firstName">
          First name
          <input
            type="text"
            value={playerInfo.firstName}
            name="firstName"
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="lastName">
          Last name
          <input
            type="text"
            value={playerInfo.lastName}
            name="lastName"
            onChange={(e) => handleChange(e)}
          />
        </label>
        <label htmlFor="email">
          Email
          <input
            type="text"
            value={playerInfo.email}
            name="email"
            onChange={(e) => handleChange(e)}
          />
          {fieldValidation.email ? 'Syötä sähköposti' : null}
        </label>
        <div className="privacyPolicyOption">
          <input
            type="checkbox"
            defaultChecked={playerInfo.policy}
            name="policy"
            onChange={(e) => handleChange(e)}
          />
          <span>
            I agree the storage and use of personal data in accordance with Exove&rsquo;s
            <a
              href="https://www.exove.com/privacy-policy-for-clients/"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer">
              {' '}
              privacy policy
            </a>
            . We don&rsquo;t spam our friends, but from time to time you will receive content which
            we think might interest you. Remember that you can cancel at any time.
          </span>
        </div>
        <div className="inputSection">
          <button id="submitbtn" type="submit">
            SEND
          </button>
          <button id="skipbtn" onClick={handleSkip} type="button">
            SKIP
          </button>
        </div>
      </form>
      <img src={heartLogo} alt="logo" />
      <Footer />
    </div>
  )
}
