import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Styles/Clicker.css'
import donut from '../Assets/exove_donut.svg'
import Loader from './Loader'
import getLoadingScreenFacts from './Facts'

const Clicker = ({ score, setScore, playerInfo }) => {
  const [seconds, setSeconds] = useState(25)
  const [isLoading, setIsLoading] = useState(true)
  const [fact1, setFact1] = useState('')
  const [fact2, setFact2] = useState('')
  const [gameId, setGameId] = useState('')

  const navigate = useNavigate()

  // create random emoji with random values for positioning within the animation
  const generateRandomEmoji = (elem, min, max) => {
    const emoji = elem
    const randomEmoji = Math.random() < 0.5 ? '&#x1F917;' : '&#x1F618;'
    const randomOp = Math.random() < 0.5 ? '-' : ''
    const randomX = Math.random() < 0.5 ? 'left' : 'right'
    const randomValue = Math.floor(Math.random() * (max - min) + min)
    emoji.innerHTML = randomEmoji
    emoji.style.top = `-${randomValue}%`
    emoji.style[randomX] = `${randomOp + randomValue}%`
    emoji.style.setProperty('--rotate', `${randomOp + randomValue}deg`)
    emoji.style.setProperty('--translateX', `${randomOp + randomValue}px`)
    return emoji
  }

  // create the emoji element on click and remove it from DOM after .2s
  const generateAnimation = (e) => {
    const emojiElement = document.createElement('span')
    const clickMeText = e.target.parentNode.firstChild
    // remove click me text on first click
    if (score.currentScore < 1) e.target.parentNode.removeChild(clickMeText)
    e.target.parentNode.appendChild(generateRandomEmoji(emojiElement, 10, 20))
    setTimeout(() => e.target.parentNode.removeChild(emojiElement), 200)
  }

  const generateRandomFacts = () => {
    const facts = getLoadingScreenFacts()
    setFact1(facts[0])
    setFact2(facts[1])
  }

  // handle click event
  const clickHandler = (e) => {
    setScore((prevState) => ({ ...prevState, currentScore: prevState.currentScore + 1 }))
    generateAnimation(e)
  }

  // HTTP requests
  const beginGameHandler = () => {
    if (!isLoading)
      fetch('/api/score/begingame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nickname: playerInfo.nickname })
      })
        .then((res) => res.json())
        .then((data) => {
          setGameId(data.savedGame.id)
        })
        .catch((error) => console.log(error))
  }

  const sendIntervalData = () => {
    fetch('/api/score/submitscore', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: gameId,
        score: score.currentScore,
        finished: false
      })
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => console.log(error))
  }

  const sendFinalData = () => {
    fetch('/api/score/submitscore', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        score: score.currentScore,
        finished: true,
        id: gameId
      })
      /* moves the score to lastScore which is diplayed
       * in the leading screen and gameover page.
       * Also resets score to 0 so the count always starts from 0
       */
    })
      .then((res) => res.json())
      .then(() => {
        setScore((prevState) => ({
          lastScore: prevState.currentScore,
          currentScore: 0,
          finished: false
        }))
        setGameId('')
      })
      .catch((error) => console.log(error))
  }

  // countdown timer with time turning red if less than 10s left
  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevState) => prevState - 1)
      }
    }, 1000)
    beginGameHandler()
    return () => clearInterval(countdown)
  }, [isLoading])

  // navigates to the loading view and then to newsletter subscription form after 5s when the time is up
  const hasSubscribed = localStorage.getItem('hasSubscribed') === 'true'
  const redirection = () => (!hasSubscribed ? navigate('/form') : navigate('/gameover'))
  useEffect(() => {
    if (seconds === 0) {
      setIsLoading(true)
      sendFinalData()
      setTimeout(() => {
        redirection()
      }, 5000)
    }

    if (seconds > 0 && seconds < 20 && seconds % 5 === 0) {
      sendIntervalData()
    }
  }, [seconds])

  // cancel loading view after 5s
  useEffect(() => {
    const hasNickname = localStorage.getItem('nickname')
    if (hasNickname) {
      generateRandomFacts()
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    } else {
      navigate('/')
    }
  }, [])

  return (
    <div className="clicker-container">
      {isLoading && seconds > 20 && <Loader heading="Get ready..." fact={fact1} />}
      {isLoading && seconds === 0 && <Loader heading="Your score was" score={score} fact={fact2} />}
      {!isLoading && (
        <>
          <div className="progress">
            <div className="bar" />
          </div>
          <button
            className="clicker"
            id="clicker"
            type="button"
            onClick={seconds > 0 ? clickHandler : null}
            disabled={seconds < 1}>
            <p className="click-text">Click me!</p>
            <img src={donut} alt="exove brand donut" draggable="false" />
          </button>
          <h1 className="number-of-clicks">{score.currentScore}</h1>
        </>
      )}
    </div>
  )
}

export default Clicker
